import { Button, Image } from 'antd'
import { t } from 'i18next'
import _ from 'lodash'
import React from 'react'
import AvailableNowCard from '../AvailableNowCard'

export default function BoxSlotListXS ({ box, daysList, more }) {
  return (
    <>
      {
          box && box.waiting_time_url &&
          <Image
            width={'30em'}
            src={box.waiting_time_url.url}
          />
      }
      {
        box && box['available_now?'] &&
        <AvailableNowCard box={box} />
      }
      {daysList}
      <Button style={{ marginTop: '2em' }} onClick={more} type="primary">{t('Public.BoxPage.more')}</Button>
    </>
  )
}
