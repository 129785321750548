import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Layout, Row, Spin } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useForm } from 'antd/es/form/Form'
import GlobalContext from '../GlobalContext'
import { post, put } from '../../Common/api'
import { ReactTrixRTEInput } from 'react-trix-rte'

export default function CommentDetail ({ eventId, comment, action, addComment }) {
  const { data } = useContext(GlobalContext)
  const [formDisplay, setFormDisplay] = useState(action === 'new')
  const [loading, setLoading] = useState(false)
  const [form] = useForm()

  useEffect(() => {
    form.setFieldsValue({
      content: comment.content_trix_html
    })
  }, [comment])

  const updateComment = async (values) => {
    await put(`admin/events/${eventId}/comments/${comment.id}`, values, (res) => {
      comment.content_trix_html = res.content_trix_html
      comment.content_string = res.content_string
      setLoading(false)
      setFormDisplay(false)
    }, () => {
      setLoading(false)
    })
  }

  const newComment = async (values) => {
    await post(`admin/events/${eventId}/comments`, values, (res) => {
      addComment(res)
      setLoading(false)
      setFormDisplay(false)
    }, () => {
      setLoading(false)
    })
  }

  const onSubmit = (values) => {
    setLoading(true)
    const params = { content: values.comment_content }
    if (action === 'new') {
      newComment(params)
    } else {
      updateComment(params)
    }
  }

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Spin spinning={loading}></Spin>
      {action === 'update' && comment.user &&
        <span>
          {dayjs(comment.created_at).format('DD/MM/YY HH[h]mm')} - {comment.user.first_name} {comment.user.last_name}
          {(comment.user_id === data.user.id) &&
            <Link ><Button type="link" icon={<FormOutlined />} onClick={() => setFormDisplay(!formDisplay)} /></Link >
          }
        </span>
      }
      {(formDisplay || (action === 'new')) &&
        <Form
          form={form}
          onFinish={onSubmit}
        >
          <Row>
            <Col span={24}>
              {comment.content_trix_html !== undefined &&
                <Form.Item name="comment_content">
                  <ReactTrixRTEInput isRailsDirectUpload={true} defaultValue={comment.content_trix_html}
                    data-direct-upload-url="/rails/active_storage/direct_uploads"
                    data-blob-url-template="/rails/active_storage/blobs/:signed_id/*filename"
                  />
                </Form.Item>
              }
            </Col>
            <Col span={4} style={{ marginBottom: '24px', display: 'flex', alignItems: 'end' }}>
              <Button type="primary" htmlType="submit" >{t('actions.comment')}</Button>
            </Col>
          </Row>
        </Form>
      }
      {
        !formDisplay &&
        <p dangerouslySetInnerHTML={{ __html: comment.content_string }} />
      }
    </Layout >
  )
}
