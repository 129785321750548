import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Button, Tag, Tooltip } from 'antd'
import { FormOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const lastPing = (o) => {
  if (!o.last_ping) {
    return 'Jamais'
  }
  return dayjs(o.last_ping).format('DD/MM/YYYY HH:mm')
}

const stateMessage = (box) => {
  const messages = t(`Admin.Box.state.${box.state}`).split(' - ')
  return (
    <Tag color ={t(`Admin.Box.stateColor.${box.state}`)}>
      {messages[0]}
      {messages[1] && <div>{messages[1]}</div>}
    </Tag>
  )
}

const columns = (screens) => [
  {
    title: t('Admin.BoxList.table.name'),
    dataIndex: 'name',
    key: 'name',
    width: 200,
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: t('Admin.BoxList.table.zipCode'),
    dataIndex: 'zip_code',
    key: 'zipCode',
    width: 120,
    sorter: (a, b) => a.zip_code - b.zip_code,
    hidden: !screens.md
  },
  {
    title: t('Admin.BoxList.table.owner'),
    dataIndex: 'owner',
    key: 'owner',
    width: 200,
    render: (_, o) => o?.owner?.name,
    sorter: (a, b) => a?.owner?.name.localeCompare(b?.owner?.name),
    hidden: !screens.md
  },
  {
    width: 90,
    title: t('Admin.BoxList.table.events_count'),
    dataIndex: 'events_count',
    key: 'events_count',
    sorter: (a, b) => a.events_count - b.events_count,
    hidden: !screens.md
  },
  {
    width: 90,
    title: t('Admin.BoxList.table.online'),
    dataIndex: 'online',
    key: 'online',
    align: 'center',
    render: (_, o) => <Tooltip title={lastPing(o)}><PoweroffOutlined style={{ color: o.is_online ? 'green' : 'red' }} /></Tooltip>,
    sorter: (a, b) => a.is_online - b.is_online
  },
  {
    width: 150,
    title: t('Admin.BoxList.table.state'),
    dataIndex: 'state',
    key: 'state',
    render: (_, o) => o ? stateMessage(o) : '',
    sorter: (a, b) => a.state - b.state
  },
  {
    width: 50,
    key: 'action',
    render: (_, o) => <Link to={`/admin/box/${o.id}/calendar`}><Button shape="circle" icon={<FormOutlined />} /></Link>
  }
].filter((col) => !col.hidden)

export default function BoxList ({ boxes }) {
  const rowClassName = (boxe) => {
    return boxe.newAlert ? 'new-alert' : ''
  }
  const screens = useBreakpoint()

  return (
    <CustTable
      rowClassName={rowClassName}
      height={'calc(100vh - 230px)'}
      initColumns={columns(screens)}
      dataSource={boxes}
    />
  )
}
