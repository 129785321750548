import React, { useContext, useState } from 'react'
import { DatePicker, Form, Input, Switch } from 'antd'
import GlobalContext from '../GlobalContext'
import { t } from 'i18next'
import TextArea from 'antd/es/input/TextArea'

export default function EventForm ({ form, onFinish, slotId, boxId }) {
  const { data } = useContext(GlobalContext)
  const [asanaTicket, setAsanaTicket] = useState(false)

  const initialValues = {
    slot_id: slotId,
    box_id: boxId,
    created_by_id: data.user.id
  }

  return (
    <Form
      id="EventFormAdmin"
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      size='small'
    >
      <Form.Item name='slot_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='box_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='created_by_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='title' label={t('Admin.Event.attributes.title')}
        rules={[{ required: true, message: t('rules.required') }]}>
        <Input />
      </Form.Item>
      <Form.Item name='content' label={t('Admin.Event.attributes.content')}
        rules={[{ required: true, message: t('rules.required') }]}>
        <TextArea />
      </Form.Item>
      <Form.Item name='asana_ticket' label={t('Admin.Event.attributes.asanaTicket')}>
        <Switch onChange={(value) => setAsanaTicket(value)} />
      </Form.Item>
      {asanaTicket &&
        <Form.Item name='date' label={t('Admin.EventList.table.asana.date')}>
          <DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
        </Form.Item>
      }
    </Form>
  )
}
