import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { PhoneFilled } from '@ant-design/icons'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import BoxSchedules from './BoxSchedules'

export default function BoxCard ({ box, disabled }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const style = styleDefault()

  const navigateTo = (e) => {
    e.stopPropagation()
    navigate({
      pathname: '/results',
      search: `?lat=${box.lat}&long=${box.long}`
    })
  }

  return (
    <>
      <div style={style.box} className="boxPageCard">
        <div>
          <img src={box.picture_url.url} style={style.image} />
        </div>
        <div style={style.content} className="content">
          <div className="text" style={{ marginBottom: '1em' }}>
            <b>{box.name}</b> <br />
            <span className="address">{box.address}<br /></span>
            {box.zip_code} {box.city}<br />
            <PhoneFilled /><a href={`tel:${box.pcc_phone}`}>{formatPhoneNumberIntl(box.pcc_phone)}</a><br/>
            <a href={`https:///faq.laboxmedicale.com/index.php/${box.zip_code}`} target="_blank" rel="noreferrer">{t('Public.BoxPage.faq')}</a>
          </div>
          {!disabled && <Button onClick={navigateTo} type="primary">{t('Public.BoxPage.change')}</Button>}
        </div>
      </div>
      <BoxSchedules box={box} />
    </>
  )
}

const styleDefault = () => ({
  box: {
    boxShadow: '0px 0px 2px 2px #f2f2f2',
    borderRadius: '1em',
    padding: '1em',
    display: 'flex',
    gap: '1em',
    width: '350px',
    margin: '1px'
  },
  image: {
    borderRadius: '1em',
    maxWidth: '100px'
  },
  content: { fontSize: '1.2em' }
})
