import React, { useContext } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Color } from '../../Common/Color'
import { Calendar, Typography } from 'antd'
import dayjs from 'dayjs'
import SpecialistContext from './SpecialistContext'
import { t } from 'i18next'

export default function SpecialistCalendar () {
  const { monthAvailabilities, date, getMonthAvailabilities, setDay, day } = useContext(SpecialistContext)

  const dateCellRender = (value) => {
    if (monthAvailabilities && date && value.$M === date.month()) {
      return <div className='custom' style={{ color: monthAvailabilities[value.$D - 1].color, '--background-color': monthAvailabilities[value.$D - 1].color, height: '49px', paddingTop: '10px', fontSize: '16px' }}>
          {value.$D}
        </div>
    } else {
      return <div style={{ height: '49px', paddingTop: '10px', fontSize: '16px' }}>{value.$D}</div>
    }
  }

  const dateInSelectionnableRange = (date) => {
    return date >= dayjs() && date <= dayjs().add(60, 'day')
  }

  const dateManipulation = (date, month) => {
    let newDate
    if (month > 11) {
      newDate = date.clone().add(1, 'month').startOf('month')
    } else if (month < 0) {
      newDate = date.clone().subtract(1, 'month').startOf('month')
    } else {
      newDate = date.clone().month(month).startOf('month')
    }
    return newDate < dayjs() && month === dayjs().month() ? dayjs() : newDate
  }

  const headerRender = ({ value, onChange }) => {
    const title = dayjs(value.$d).format('MMMM YYYY')
    const onClick = (newMonth) => {
      const newDate = dateManipulation(value, newMonth)
      if (dateInSelectionnableRange(newDate)) {
        getMonthAvailabilities(newDate)
        onChange(newDate)
      }
    }
    const canNavigatePrev = dateInSelectionnableRange(dateManipulation(value, value.clone().subtract(1, 'month').month()))
    const canNavigateNext = dateInSelectionnableRange(dateManipulation(value, value.clone().add(1, 'month').month()))
    const style = { fontSize: '2em', margin: '0.5em 0.7em 0 0.7em', cursor: 'pointer', '--hover-color': Color.red }
    const disabled = { cursor: 'not-allowed', color: 'grey' }

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: Color.green, borderRadius: '16px 16px 0 0', color: Color.white }}>
        <div className={canNavigatePrev ? 'hoverable' : ''} onClick={() => { onClick(value.month() - 1) }} style={canNavigatePrev ? style : { ...style, ...disabled }}><LeftOutlined /></div>
        <Typography.Title level={4} style={{ color: Color.white }}>{title}</Typography.Title>
        <div className={canNavigateNext ? 'hoverable' : ''} onClick={() => { onClick(value.month() + 1) }} style={canNavigateNext ? style : { ...style, ...disabled }}><RightOutlined /></div>
      </div>
    )
  }

  const onSelect = (selectedDay) => {
    if (selectedDay.month() !== day.month()) {
      getMonthAvailabilities(selectedDay.startOf('month'))
    }
    setDay(selectedDay)
  }

  return (
    <>
      <Typography.Title level={2} style={{ marginBottom: '1em', textAlign: 'center' }}>{t('Public.Specialist.dateSelection')}</Typography.Title>
      <Calendar fullscreen={false} dateFullCellRender={dateCellRender} headerRender={headerRender} onChange={onSelect} style={{ width: '30em', boxShadow: '1px 1px 10px #4f5d75' }}
      validRange={[dayjs().subtract(1, 'day'), dayjs().add(60, 'day')]} />
    </>
  )
}
