import React from 'react'
import { Col, Row } from 'antd'
import GepetoCard from './GepetoCard'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const Cards = ({ gepetos, updateGepeto }) => {
  const screens = useBreakpoint()
  const span = screens.xs ? 24 : 6
  const result = []
  gepetos.forEach((gepeto) => {
    result.push(
    <Col span={span} key={gepeto.id}>
      <GepetoCard gepeto={gepeto} updateGepeto={updateGepeto} />
      </Col>
    )
  })
  return result
}

export default function GepetoCards ({ gepetos, updateGepeto }) {
  const pad = { xs: 8, sm: 16, md: 24, lg: 32 }

  return (
    <Row gutter={[pad, pad]} justify="center">
      <Cards gepetos={gepetos} updateGepeto={updateGepeto} />
    </Row>
  )
}
