import React from 'react'
import { Card, Col, Row } from 'antd'
import PublicFooter from '../PublicFooter'
import PublicHeader from '../PublicHeader'
import { Color } from '../../Common/Color'

const news = (<>
  <div style={{ backgroundColor: '#fff', borderRadius: '0 30px 30px 0' }}>
    <div className="contentPadding" style={{ maxWidth: '1200px', margin: '2.5em 0' }}>
      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
        La Box Médicale est ouverte 7 jours sur 7 depuis janvier 2024
      </div>
      <div style={{ fontSize: '18px' }}>
        <p>Face aux difficultés croissantes pour trouver un médecin sur certains territoires, La Box Médicale a décidé d&apos;ouvrir le dimanche pour la rendre accessible 7 jours sur 7 sur l&apos;ensemble des Box déployées.</p>
        <p>La Box Médicale lutte contre le renoncement aux soins particulièrement dans les déserts médicaux. Nous nous engageons aux côtés des collectivités pour donner accès aux médecins généralistes et aux spécialistes au plus vite et au plus proche.</p>
      </div>
    </div>
  </div>
</>)

export default function HomePage () {
  return (<div style={{ gap: '1em', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: Color.white }}>
    <PublicHeader withSearch={true} />
    <Row style={{ background: 'linear-gradient(white, teal, white)' }} className='hide-md'>
      <Col span={8} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        {news}
      </Col>
      <Col span={16}>
        <img style={{}} src="/images/box-exterieur.png" title="Image de la Box extérieur" height={400} />
      </Col>
    </Row>
    <Row style={{ background: 'linear-gradient(white, teal, white)' }} className='show-md'>
      <Col span={23} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        {news}
      </Col>
    </Row>
    <div>
      <div className="contentPadding" style={cssTitle}>
        Nous facilitons l&apos;accès à la santé
      </div>
    </div>
    <div className="contentPadding" style={{ fontSize: '18px', maxWidth: '1200px' }}>
      Pour répondre à la diminution de l&apos;offre de soin traditionnelle et permettre au plus grand nombre d&apos;avoir accès à un service de santé de proximité, nous avons créé la Box Médicale.
    </div>
    <div>
      <div className="contentPadding" style={{ fontSize: '30px', fontWeight: 'bold', color: '#ff4438', display: 'inline-block' }}>
        Notre solution est
      </div>
    </div>
    <div className="contentPadding">
      <Row>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/securise.svg" title="Sécurisé" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Sécurisée
          </div>
          Un espace automatisé et supervisé par nos équipes en temps réel.
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/facile-acces.svg" title="Facile d'accès" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Facile d&apos;accès
          </div>
          Un dispositif innovant ouvert 7/7,<br />
          pour un rendez-vous immédiat ou dans un proche délai
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/automatisee.svg" title="Automatisée" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Automatisée
          </div>
          Un espace où les patients peuvent se rendre en toute autonomie pour téléconsulter.
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/au-plus-pres.svg" title="Au plus près" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Au plus près
          </div>
          Une solution sans limite d&apos;implantation pour être au plus proche de chacun
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/100-francaise.svg" title="100% française" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            100% française
          </div>
          De l&apos;idée à la conception la Box est un produit Made in France.
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/hygienique.svg" title="Hygiénique" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Hygiénique
          </div>
          L&apos;hygiène est centrale dans notre démarche avec une désinfection complète par UV-C.
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/confidentielle.svg" title="Confidentielle" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Confidentielle
          </div>
          L&apos;espace de soins est dédié et reste discret comme chez le praticien.
        </Card>
      </Row>
      <div style={{ fontSize: '30px', fontWeight: 'bold', padding: '0.5em 0', display: 'inline-block' }}>
        Les examens disponibles
      </div>
      <div style={{ fontSize: '18px', maxWidth: '1200px', padding: '0.5em 0' }}>
        Le cabinet médical dispose de dimensions confortables et est connecté à un réseau majeur de médecins,
        la Box est équipée de l&apos;ensemble des instruments de mesure nécessaires au diagnostic médical et à un examen clinique.
      </div>
      <div style={{ fontSize: '30px', fontWeight: 'bold', padding: '0.5em 0', color: '#ff4438', display: 'inline-block' }}>
        Une téléconsultation augmentée
      </div>
      <div style={{ fontSize: '18px', maxWidth: '1200px', padding: '0.5em 0' }}>
        La borne est équipée de 6 dispositifs connectés&nbsp;:
      </div>
      <Row>
        <div style={cssMaterial}>Thermomètre</div>
        <div style={cssMaterial}>Tensiomètre</div>
        <div style={cssMaterial}>Oxymètre</div>
        <div style={cssMaterial}>Dermatoscope</div>
        <div style={cssMaterial}>Otoscope</div>
        <div style={cssMaterial}>Stéthoscope</div>
      </Row>
    </div>
    <div>
      <div className="contentPadding" style={cssTitle}>Déroulé d&apos;une consultation</div>
    </div>
    <div className='hide-md contentPadding'>
      <Row>
        <Col span={16}>
          <div style={{ fontSize: '30px', fontWeight: 'bold', padding: '0.5em 0', display: 'inline-block' }}>
            A l&apos;extérieur
          </div>
          <div style={{ fontSize: '25px' }} >
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>1</span>
              Prenez un rendez-vous sur <a href="https://www.laboxmedicale.com" style={{ color: '#ff4438', fontWeight: 'bold' }}>www.laboxmedicale.com</a> ou Flashez le QR code ou appelez-nous
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>2</span>
              Réservez votre créneau horaire ou prenez un rendez-vous immédiat
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>3</span>
              Recevez le code d&apos;accès à la Box par SMS
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>4</span>
              C&apos;est l&apos;heure de votre rendez-vous&nbsp;: <b>tapez le code</b>
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>5</span>
              La porte s&apos;ouvre, nous vous souhaitons une bonne consultation !
            </div>
          </div>
        </Col>
        <Col span={4}><img style={{}} src="/images/box-exterieur.png" title="Image de la Box extérieur" height={200} /></Col>
      </Row>
      <Row>
        <Col span={16}>
          <div style={{ fontSize: '30px', fontWeight: 'bold', padding: '0.5em 0', display: 'inline-block' }}>
            A l&apos;intérieur
          </div>
          <div style={{ fontSize: '25px' }} >
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>6</span>Installez vos affaires sur le mobilier
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>7</span>Dirigez-vous vers la borne
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>8</span>Votre consultation commence
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>9</span>En cas de besoin utilisez l&apos;interphone, un opérateur va vous répondre
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>10</span>Après votre départ, la désinfection de la Box est réalisée automatiquement
            </div>
          </div>
        </Col>
        <Col span={4}><img style={{}} src="/images/box-interieur.svg" title="Image de la Box intérieur" height={200} /></Col>
      </Row>
    </div >
    <div className='show-md contentPadding'>
      <Row>
        <Col span={20}>
          <div style={{ fontSize: '30px', fontWeight: 'bold', padding: '0.5em 0', display: 'inline-block' }}>
            A l&apos;extérieur
          </div>
          <div style={{ fontSize: '20px' }} >
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>1</span>
              Prenez un rendez-vous sur <a href="https://www.laboxmedicale.com" style={{ color: '#ff4438', fontWeight: 'bold' }}>www.laboxmedicale.com</a> ou Flashez le QR code ou appelez-nous
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>2</span>
              Réservez votre créneau horaire ou prenez un rendez-vous immédiat
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>3</span>
              Recevez le code d&apos;accès à la Box par SMS
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>4</span>
              C&apos;est l&apos;heure de votre rendez-vous&nbsp;: <b>tapez le code</b>
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>5</span>
              La porte s&apos;ouvre, nous vous souhaitons une bonne consultation !
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <div style={{ fontSize: '30px', fontWeight: 'bold', padding: '0.5em 0', display: 'inline-block' }}>
            A l&apos;intérieur
          </div>
          <div style={{ fontSize: '20px' }} >
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>6</span>Installez vos affaires sur le mobilier
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>7</span>Dirigez-vous vers la borne
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>8</span>Votre consultation commence
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>9</span>En cas de besoin utilisez l&apos;interphone, un opérateur va vous répondre
            </div>
            <div style={{ paddingBottom: '0.5em' }}>
              <span style={cssNumber}>10</span>Après votre départ, la désinfection de la Box est réalisée automatiquement
            </div>
          </div>
        </Col>
      </Row>
    </div >
    <PublicFooter />
  </div>)
}
const cssTitle = {
  fontSize: '30px',
  fontWeight: 'bold',
  borderWidth: '2px 2px 2px 0px',
  borderStyle: 'solid',
  borderRadius: '0px 30px 30px 0px',
  display: 'inline-block',
  margin: '0.5em 0'
}

const cssMaterial = {
  fontSize: '25px',
  fontWeight: 'bold',
  borderWidth: '2px',
  borderColor: 'rgb(240, 240, 240)',
  borderStyle: 'solid',
  borderRadius: '25px',
  padding: '0.5em 60px',
  margin: '0 20px 20px 0'
}

const cssNumber = {
  color: '#ff4438',
  fontWeight: 'bold',
  display: 'inline-block',
  width: '1.5em'
}
