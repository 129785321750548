import { Layout, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { get, put } from '../../Common/api'
import EventList from './EventList'
import consumer from '../../Common/cable'
import EventDetail from './EventDetail'
import { useParams } from 'react-router'
import EventFilter from './EventFilter'

export default function EventsPage () {
  const [events, setEvents] = useState([])
  const [event, setEvent] = useState({})
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState({})
  const { id } = useParams()

  useEffect(() => {
    componentDidMount()
    if (id) { showEvent(id) }
  }, [])

  const updateEvents = (updatedEvent) => {
    if (updatedEvent?.id) {
      if (events.find((e) => e.id === updatedEvent.id)) {
        setEvents(events.map((e) => (e.id === updatedEvent.id) ? { ...e, ...updatedEvent } : e))
        if (selectedEvent.id === updatedEvent.id) {
          setSelectedEvent({ ...selectedEvent, ...updatedEvent })
        }
      } else {
        setEvents([{ ...updatedEvent, new: true }, ...events])
      }
    }
  }

  useEffect(() => {
    updateEvents(event)
  }, [event])

  const componentDidMount = () => {
    consumer.subscriptions.create({ channel: 'EventsChannel' }, {
      connected: () => { console.log('connected') },
      disconnected: () => { setEvents([]) },
      received: (data) => { setEvent({ ...data.event, content: data.content_trix_html }) }
    })
  }

  const getEvents = async (filters) => {
    setLoading(true)
    await get('admin/events', filters, (res) => {
      setLoading(false)
      setEvents(res.events)
    }, () => {
      setLoading(false)
    })
  }

  const updateEvent = async (event) => {
    setLoading(true)
    await put(`admin/events/${event.id}`, event, (newEvent) => {
      setSelectedEvent({ ...newEvent.event, content: newEvent.content_trix_html, comments: event.comments })
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  }

  const getComments = async (id) => {
    setLoading(true)
    await get(`admin/events/${id}`, {}, (res) => {
      setLoading(false)
      setSelectedEvent({ ...res.event, comments: res.comments, content: res.event.content_trix_html })
    }, () => {
      setLoading(false)
    })
  }

  const showEvent = (id) => {
    getComments(id)
    setOpen(true)
  }

  const onClose = () => {
    setSelectedEvent({})
    setOpen(false)
  }

  return (
    <Layout>
      <Spin spinning={loading}>
        <EventFilter setLoading={setLoading} getEvents={getEvents} />
        <EventList events={events} showEvent={showEvent} />
        <EventDetail event={selectedEvent} open={open} onClose={onClose} updateEvent={updateEvent} setSelectedEvent={setSelectedEvent} />
      </Spin>
    </Layout>
  )
}
