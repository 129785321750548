import React from 'react'
import { notification } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { stripHtml } from './Common'

const displayNotification = (title, body, htmlBody) => {
  // eslint-disable-next-line no-new
  new Notification(title, { body })
  notification.destroy()
  notification.info({ message: title, description: htmlBody || body, duration: 0 })
}

const HtmlBodyEvent = ({ event, body, navigate }) => {
  return (<>
    <div>{body}</div>
    <div><a onClick={() => navigate(`/admin/events/${event.id}`)}>{t('Admin.notification.event.link')}</a></div>
  </>)
}

const HtmlBodySlot = ({ slot, body, navigate }) => {
  return (<>
    <div>{body}</div>
    <div><a onClick={() => navigate(`/admin/slots/${slot.id}`)}>{t('Admin.notification.slot.link')}</a></div>
  </>)
}

const notifyEvent = (navigate, event, messageType) => {
  const translateType = t(`Admin.notification.event.type.${messageType}`)
  const title = `${event.box.name} ${translateType} : ${event.title}`
  const body = stripHtml(event.content_trix_html).slice(0, 100)
  displayNotification(title, body, <HtmlBodyEvent event={event} body={body} navigate={navigate} />)
}

const newComment = (navigate, event, user) => {
  const title = `${event.box.name} ${event.title}`
  const body = `Nouveau commentaire de ${user.first_name} ${user.last_name}`
  displayNotification(title, body, <HtmlBodyEvent event={event} body={body} navigate={navigate} />)
}

const updateBox = (navigate, box) => {
  const title = box.name
  const body = t(`Admin.Box.state.${box.state}`)
  displayNotification(title, body)
}

const slot = (navigate, messageType, slot, user) => {
  const title = `${slot.box.name} : ${dayjs(slot.begin_date).format('DD/MM H[h]mm')}-${dayjs(slot.end_date).format('H[h]mm')}`
  const body = `${user.first_name} ${user.last_name} ${t(`Admin.notification.slot.${messageType}`)}`
  displayNotification(title, body, <HtmlBodySlot slot={slot} body={body} navigate={navigate} />)
}

export const lbmNotification = (navigate, data) => {
  switch (data.entity_type) {
    case 'event':
      notifyEvent(navigate, data.entity, data.message_type)
      break
    case 'comment':
      newComment(navigate, data.entity, data.user)
      break
    case 'box':
      updateBox(navigate, data.entity)
      break
    case 'slot':
      slot(navigate, data.message_type, data.entity, data.user)
      break
    default:
      break
  }
}
