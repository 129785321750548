import React, { useState } from 'react'
import { t } from 'i18next'
import { Button, Col, Row, Spin } from 'antd'
import { Outlet } from 'react-router'
import { put } from '../../Common/api'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

export const GepetoInfo = ({ gepeto }) => (
  <div>
    <div>{t('Admin.GepetoList.table.ip')}: {gepeto.ip}</div>
    <div>{t('Admin.GepetoList.table.latency')}: {gepeto.latency}ms</div>
    <div>{t('Admin.GepetoList.table.command')}: {gepeto.command}</div>
    <div>{t('Admin.GepetoList.table.box_state')}: {t(`Admin.Box.state.${gepeto.box.state}`)}</div>
    <div>{t('Admin.GepetoList.table.gepeto_state')}: {gepeto.data?.state}</div>
  </div>
)

export const DisplayData = ({ gepeto }) => {
  const data = gepeto.data || {}
  return Object.keys(data).map(key => (
    <div key={key}>{t(`Admin.GepetoData.${key}`) + ': ' + data[key]}</div>
  ))
}

export const Commands = ({ gepeto, setGepeto }) => {
  const [loading, setLoading] = useState(false)
  const sendCommand = async (command) => {
    setLoading(true)
    await put(`admin/gepetos/${gepeto.id}`, command, (json) => {
      setGepeto(json)
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  }

  const screens = useBreakpoint()
  const span = screens.xs ? '24' : '12'
  const result = []
  const commands = ['empty_clean', 'busy', 'empty_to_clean', 'cleaning']
  commands.forEach((command) => {
    result.push(
      <Col key={command} span={span}>
        <Button style={{ margin: '1em' }} disabled={loading || gepeto.command} key={command} onClick={() => { sendCommand({ command }) }}>
          {t(`Admin.GepetoList.command.${command}`)}
        </Button>
      </Col>
    )
  })
  return (
    <>
      <Spin spinning={loading}> <Outlet /> </Spin>
      <Row>{result}</Row>
    </>)
}
