export const Color = {
  red: '#fa4c4f',
  green: '#23b091',
  light_green: '#adedc8',
  primary_blue: '#40a9ff',
  dark_blue: '#243746',
  light_blue: '#bddae5',
  dark_orange: '#fab038',
  orange: '#fcd79c',
  light_orange: '#fef3e1',
  white: '#fff',
  black: '#000',
  grey: '#E0E0E0',
  primary: '#FF4438',
  period_open: '#3788d8',
  period_cleaning: '#a52a2a',
  period_consulting: '#800080'
}

export const SLOT_COLORS = {
  clean: '#35bba0',
  maintenance: '#ff4438',
  patient: '#243746'
}
