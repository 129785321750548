import React from 'react'
import { Col, Row } from 'antd'
import BoxCard from './BoxCard'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const Cards = ({ boxes }) => {
  const screens = useBreakpoint()
  const span = screens.xs ? 24 : 6
  const result = []
  boxes.forEach((box) => {
    result.push(<Col span={span} key={box.id}><BoxCard box={box} /></Col>)
  })
  return result
}

export default function BoxCards ({ boxes }) {
  const pad = { xs: 8, sm: 16, md: 24, lg: 32 }
  return (
    <Row gutter={[pad, pad]} justify="center">
      <Cards boxes={boxes} />
    </Row>
  )
}
