import { Button, Layout, Modal, Popover, Spin } from 'antd'
import { t } from 'i18next'
import React, { useContext } from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import BoxContext from './BoxContext'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import PeriodForm from '../PeriodPage/PeriodForm'
import { Color } from '../../Common/Color'

dayjs.extend(weekday)
dayjs.extend(localeData)

const periodTitle = (period) => (
  t(`Admin.BoxCalendar.periodTitle.${period.period_type}`)
)

const periodDate = (weekday, date) => {
  weekday = weekday === 0 ? 6 : weekday - 1
  const datejs = dayjs(date)
  return dayjs().weekday(weekday).set('second', 0).set('hour', datejs.hour()).set('minute', datejs.minute())
}

const transformPeriod = (day, period) => {
  return {
    id: period.id,
    periodType: period.period_type,
    intervenerId: period.intervener_id,
    intervenerName: period.intervener_name,
    color: Color[`period_${period.period_type}`],
    title: periodTitle(period),
    start: periodDate(day.week_day, period.start).format(),
    end: periodDate(day.week_day, period.end).format()
  }
}

export default function BoxSetupCalendarPage () {
  const { days, loadDefaultDays, box, savePeriod, deletePeriod, loading } = useContext(BoxContext)
  const [form] = useForm()

  const renderEventContent = (eventInfo) => (
    <Popover content={eventInfo.event.extendedProps.intervenerName}>
      <div style={{ whiteSpace: 'nowrap', height: '100%' }} onClick={() => displayEditModal(eventInfo)} >
        {eventInfo.event.title}
      </div>
    </Popover>
  )

  const displayEditModal = (eventInfo) => {
    form.resetFields()
    form.setFieldsValue({
      id: eventInfo.event.id,
      period_type: eventInfo.event.extendedProps.periodType,
      intervener_id: eventInfo.event.extendedProps.intervenerId,
      range: [dayjs(eventInfo.event.start), dayjs(eventInfo.event.end)]
    })
    modal(t('Admin.BoxCalendar.updatePopupTitle'), true)
  }

  const displayCreateModal = (info) => {
    form.resetFields()
    form.setFieldsValue({
      week_day: dayjs(info.start).weekday() === 6 ? 0 : dayjs(info.start).weekday() + 1,
      period_type: 'open',
      range: [dayjs(info.start), dayjs(info.end)]
    })
    modal(t('Admin.BoxCalendar.createPopupTitle'))
  }

  const onFinish = async () => {
    if (form.getFieldValue('period_type') === 'cleaning' && !form.getFieldValue('intervener_id')) {
      form.validateFields()
    } else {
      await savePeriod(form.getFieldsValue(), form, () => Modal.destroyAll())
    }
  }

  const onDelete = async () => {
    await deletePeriod(form.getFieldValue('id'), () => Modal.destroyAll())
  }

  const modal = (title, deleteButton = false) => Modal.confirm({
    icon: <></>,
    title,
    width: '800px',
    okText: t('actions.validate'),
    confirmLoading: loading,
    maskClosable: true,
    autoFocusButton: null,
    footer: <div style={deleteButton ? { display: 'flex', justifyContent: 'space-between' } : { textAlign: 'right' }}>
      {deleteButton && <Button onClick={onDelete} type="primary">{t('actions.delete')}</Button>}
      <div>
        <Button onClick={() => Modal.destroyAll()} style={{ marginRight: '1em' }}>{t('actions.cancel')}</Button>
        <Button onClick={onFinish} type="primary">{t('actions.validate')}</Button>
      </div>
    </div>,
    content: <Spin spinning={loading}>
      <PeriodForm form={form} interveners={box.interveners.filter(i => i.job === 'cleaning')} cleanable={box['cleaning_period_creatable?']} />
    </Spin>
  })

  return (
    <>
      {box &&
        <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
          {
            box.id && <FullCalendar
              height="auto"
              eventClassNames='default-week-calendar-event'
              dayCellClassNames='default-week-calendar-cell'
              plugins={[timeGridPlugin, interactionPlugin]}
              headerToolbar={{ left: '', center: '', right: '' }}
              dayHeaderFormat={{ weekday: 'long' }}
              selectable={true}
              firstDay={1}
              initialView="timeGridWeek"
              slotDuration="01:00:00"
              locale={frLocale}
              allDaySlot={false}
              displayEventTime={false}
              events={days?.map((day) => day.periods.map((period) => transformPeriod(day, period))).flat()}
              eventContent={renderEventContent}
              datesSet={async () => await loadDefaultDays(box.id)}
              select={(info) => displayCreateModal(info)}
              slotMinTime={'00:00:00'}
              slotMaxTime={'24:00:00'}
            />
          }
        </Layout>
      }
    </>
  )
}
