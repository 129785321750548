import React, { useEffect, useState } from 'react'
import { post } from '../../Common/api'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(weekday)

export default function BoxSchedules ({ box }) {
  const [days, setDays] = useState([])
  const style = {
    boxShadow: '0px 0px 2px 2px #f2f2f2',
    borderRadius: '1em',
    padding: '1em 0',
    gap: '1em',
    width: '350px',
    margin: '1px'
  }

  const weekDayOrder = [1, 2, 3, 4, 5, 6, 0]

  const getDays = async () => {
    await post(`public/boxes/${box.id}/box_schedules`, {}, (res) => {
      setDays(res)
    })
  }

  useEffect(() => {
    getDays()
  }, [])

  return (
    <div style={style}>
      <div style={{ display: 'flex', justifyContent: 'space-around', minWidth: '25em' }}>
        <div style={{ fontSize: '1.2em' }}>
          <div>
            <p style={{ margin: '0 0 0.5em 0' }} ><b>Horaires</b></p>
            { weekDayOrder.map(day => {
              return <p key={`schedules-${day}`} style={{ margin: '0 0' }}>{`${dayjs().weekday(day - 1 % 7).format('dddd')} :`}</p>
            })}
          </div>
        </div>
        <div style={{ fontSize: '1.2em' }}>
          <div>
            <p style={{ margin: '0 0 0.5em 0' }}><b>Ouvertures</b></p>
            {weekDayOrder.map(day => {
              const currentDay = days.find(e => e.week_day === day)
              if (currentDay && currentDay.open) {
                const start = dayjs(currentDay.open.start).format('H')
                const end = dayjs(currentDay.open.end).format('H')
                return <p key={`open-${day}`} style={{ margin: '0 0' }}>{`${start}h-${end}h`}</p>
              } else {
                return <p key={`open-${day}`} style={{ margin: '0 0' }}>Fermée</p>
              }
            })}
          </div>
        </div>
        <div style={{ fontSize: '1.2em' }}>
          <div>
            <p style={{ margin: '0 0 0.5em 0' }}><b>Consultations</b></p>
            {weekDayOrder.map(day => {
              const currentDay = days.find(e => e.week_day === day)
              if (currentDay && currentDay.consulting) {
                const start = dayjs(currentDay.consulting.start).format('H:mm')
                const end = dayjs(currentDay.consulting.end).format('H:mm')
                return <p key={`consulting-${day}`} style={{ margin: '0 0' }}>{`${start.replace(':', 'h')}-${end.replace(':', 'h')}`}</p>
              } else {
                return <p key={`consulting-${day}`} style={{ margin: '0 0' }}>Fermée</p>
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
