export function onlyUnique (value, index, array) {
  return array.indexOf(value) === index
}

export const JOBS = ['cleaning', 'maintenance', 'municipacility', 'territory_responsable', 'emergency']

export function stripHtml (html) {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}
