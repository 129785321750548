import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Button } from 'antd'
import { FormOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Color } from '../../Common/Color'
import { stripHtml } from '../../Common/Common'

const columns = (showEvent) => [
  {
    width: 40,
    title: t('Admin.AlertList.table.created_at'),
    dataIndex: 'created_at',
    key: 'created_at',
    render: (_, o) => `${dayjs(o.created_at).format('DD/MM/YYYY HH:mm')}`
  },
  {
    width: 40,
    title: t('Admin.EventList.table.number'),
    dataIndex: 'number',
    key: 'number',
    sorter: (a, b) => a.number.localeCompare(b.number)
  },
  {
    width: 15,
    title: t('Admin.EventList.table.resolved'),
    dataIndex: 'resolved',
    key: 'resolved',
    render: (resolved) => resolved ? <CheckOutlined style={{ color: Color.green }} /> : <CloseOutlined style={{ color: Color.red }} />
  },
  {
    width: 100,
    title: t('Admin.EventList.table.title'),
    dataIndex: 'title',
    key: 'title',
    sorter: (a, b) => a.title.localeCompare(b.title)
  },
  {
    width: 130,
    title: t('Admin.EventList.table.content'),
    dataIndex: 'content_trix_html',
    key: 'content',
    render: (contentTrixHtml) => <span style={{ textOverflow: 'ellipsis' }}>{stripHtml(contentTrixHtml)}</span>
  },
  {
    width: 30,
    title: t('Admin.EventList.table.comment_count'),
    dataIndex: 'comments_count',
    key: 'comments_count'
  },
  {
    width: 40,
    title: t('Admin.EventList.table.box'),
    dataIndex: 'box',
    key: 'box',
    sorter: (a, b) => a.box - b.box,
    render: (_, event) => (event.box && <Link to={`/admin/box/${event.box.id}/calendar`}>{event.box.name}</Link>)
  },
  {
    width: 20,
    key: 'action',
    render: (_, o) => (<Link onClick={() => showEvent(o.id)} > <Button shape="circle" icon={<FormOutlined />} /></Link >)
  }
]

export default function EventList ({ events, showEvent }) {
  const rowClassName = (record) => {
    return record.new ? 'new-event' : ''
  }

  return (
    <CustTable
      style={{ height: 'calc(100vh - 170px)' }}
      height={'calc(100vh - 220px)'}
      initColumns={columns(showEvent)}
      key={'id'}
      rowClassName={rowClassName}
      dataSource={events}
      pagination={{ position: ['topLeft'], showSizeChanger: false, size: 'default', pageSize: 13 }}
    />
  )
}
