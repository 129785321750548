import React from 'react'
import { t } from 'i18next'
import { Button, Card, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { Color } from '../../Common/Color'

const stateMessage = (box) => {
  const messages = t(`Admin.Box.state.${box.state}`).split(' - ')
  return (
    <Tag color={t(`Admin.Box.stateColor.${box.state}`)}>
      {messages[0]}
      {messages[1] && <div>{messages[1]}</div>}
    </Tag>
  )
}

export default function BoxCard ({ box }) {
  const title = (<div>
    <span style={{ color: box.is_online ? Color.green : Color.dark_orange, padding: '1em' }}>{box.name} - {box.zip_code}</span>
    </div>)
  return (
    <Card title={title} bordered={true}
      actions={[
        <Link key='edit' to={`/admin/box/${box.id}/calendar`}><Button>Edition</Button></Link>
      ]}>
        {stateMessage(box)}
    </Card>
  )
}
