import { Button, Image } from 'antd'
import { t } from 'i18next'
import _ from 'lodash'
import React from 'react'
import AvailableNowCard from '../AvailableNowCard'

export default function BoxSlotListXXL ({ box, daysList, more }) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {
          box && box['available_now?'] &&
          <AvailableNowCard box={box} />
        }
        {
            box && box.waiting_time_url &&
            <Image
              width={'70em'}
              src={box.waiting_time_url.url}
            />
        }
      </div>
      {daysList}
      <Button style={{ marginTop: '2em' }} onClick={more} type="primary">{t('Public.BoxPage.more')}</Button>
    </>
  )
}
