{
  "Admin": {
    "AdminSlotPage": {
      "cancelSlot": "Êtes vous sûr d'annuler le rendez-vous ?",
      "logHeader": "Historique"
    },
    "AlertList": {
      "table": {
        "box": "Box",
        "created_at": "Date",
        "level": "Niveau",
        "message": "Message",
        "slot": "Rdv"
      }
    },
    "Box": {
      "availableNow": {
        "button": "Créer un rendez-vous immédiat",
        "title": "Box disponible immédiatement"
      },
      "level": {
        "error": "Anomalie",
        "maintenance": "Maintenance",
        "operation": "En service",
        "stopped": "Arrêt",
        "unknow": "Inconnu"
      },
      "menu": {
        "calendar": "Agenda",
        "info": "Information",
        "logs": "Historique d'activités",
        "opening": "Paramétrage calendrier"
      },
      "online": {
        "false": "Hors ligne",
        "true": "En ligne"
      },
      "state": {
        "busy": "Box occupée",
        "busy_calm_err": "511 : Box occupée - aucun mouvement",
        "busy_open_err": "510 : Box occupée - Porte ouverte",
        "cleaning": "Box en nettoyage",
        "cleaning_move_err": "531 : Box en nettoyage - détection de mouvement",
        "cleaning_no_watt_err": "530 : Box en nettoyage - Lampe éteinte",
        "empty_busy_2_err": "503 : Box vide et propre - Détection de mouvement sans ouverture de porte",
        "empty_busy_err": "501 : Box vide et propre - Détection de mouvement sans digicode",
        "empty_clean": "Box vide et propre",
        "empty_no_closed_err": "502 : Box vide et propre - Porte ouverte sans digicode",
        "empty_open_err": "500 : Box vide et propre - Porte ouverte",
        "empty_to_clean": "Box vide et à nettoyer",
        "maintenance": "Maintenance (Box en arrêt)",
        "stopped": "Box en arrêt",
        "to_clean_open_err": "520 : Box vide et à nettoyer - Porte ouverte",
        "unknow": "Aucun"
      },
      "stateColor": {
        "busy": "lime",
        "busy_calm_err": "red",
        "busy_open_err": "red",
        "cleaning": "blue",
        "cleaning_move_err": "red",
        "cleaning_no_watt_err": "red",
        "empty_busy_2_err": "red",
        "empty_busy_err": "red",
        "empty_clean": "green",
        "empty_no_closed_err": "red",
        "empty_open_err": "red",
        "empty_to_clean": "cyan",
        "maintenance": "orange",
        "stopped": "purple",
        "to_clean_open_err": "red",
        "unknow": "grey"
      }
    },
    "BoxCalendar": {
      "createPopupTitle": "Ajouter une Période",
      "periodTitle": {
        "consulting": "Consultations",
        "cleaning": "Nettoyage",
        "open": "Box ouverte"
      },
      "popupTitle": "Ajouter un RDV",
      "updatePopupTitle": "Modifier une Période"
    },
    "BoxFilter": {
      "level": "Sélectionner un état",
      "name": "Nom",
      "online": "En ligne",
      "owner": "Sélectionner une collectivité",
      "zipCode": "Code Postal"
    },
    "BoxForm": {
      "active": "Active",
      "address": "Adresse",
      "calendoc_id": "Calendoc ID",
      "city": "Ville",
      "comment": "Commentaire",
      "daily_code": "Code journalier",
      "emergency_code": "Code urgence",
      "extra_address": "Complément d'adresse",
      "gps": "Coordonnées GPS",
      "group": {
        "address": "Adresse",
        "contact": "Contact",
        "interveners": "Intervenants",
        "maintenance": "Maintenance",
        "nordnet": "Nordnet"
      },
      "inactive": "Inactive",
      "interphone": "Interphone",
      "lock_1": "Nettoyage",
      "lock_2": "Stockage",
      "lock_3": "Électrique",
      "lock_4": "Électronique",
      "locks": "Cadenas",
      "name": "Nom",
      "nordnet_email": "Login  Nordnet",
      "nordnet_number": "Numéro Client Nordnet",
      "owner": "Collectivité",
      "pcc_phone": "Téléphone PCC",
      "picture": "Image de la box",
      "waitingTime": "Image du temps d'attente",
      "pictureButton": "Choisir une image",
      "zip_code": "Code postal"
    },
    "BoxInfoPage": {
      "title": "Informations de la box"
    },
    "BoxList": {
      "table": {
        "events_count": "Incidents",
        "name": "Nom",
        "online": "En ligne",
        "owner": "Collectivité",
        "state": "État",
        "zipCode": "Code postal"
      }
    },
    "BoxesPage": {
      "addBoxTitle": "Ajouter une box",
      "title": "éléments"
    },
    "Event": {
      "asana_url": "Lien Asana",
      "attributes": {
        "asanaTicket": "Ticket pour support niveau 2",
        "content": "Description",
        "title": "Titre"
      },
      "create": "Un problème rencontré?",
      "createButton": "Créer un incident",
      "title": {
        "create": "Création d'un incident"
      },
      "unresolved": "Non résolu"
    },
    "EventFilter": {
      "box_id": "Box",
      "day": "Aujourd'hui",
      "false": "Non",
      "month": "30 derniers jours",
      "name": "Nom du filtre",
      "newFilter": "Nouveau Filtre",
      "number": "Numéro d'incident",
      "period": "Période",
      "resolved": "Résolu",
      "true": "Oui",
      "week": "7 derniers jours"
    },
    "EventList": {
      "addAsana": "Création ticket pour niveau 2",
      "notification": {
        "activated": "Les notifications sont activées",
        "button": "Affichez les notifications"
      },
      "table": {
        "alert": "Alerte",
        "asana": {
          "date": "Date & heure où l'incident a eu lieu",
          "content": "Description",
          "title": "Titre",
          "url": "Lien utile"
        },
        "asana_url": "Transféré au niveau 2",
        "box": "Box",
        "comment_count": "Commentaires",
        "comments": "Commentaires",
        "createAsana": "Créer ticket pour niveau 2",
        "created_at": "Date de création",
        "content": "Description",
        "number": "Numéro",
        "resolved": "Résolu",
        "resolved_at": "Date de résolution",
        "slot": "Rdv",
        "title": "Titre"
      }
    },
    "GepetoData": {
      "daily_code": "Code journalier",
      "door_state": "Porte",
      "door_state_since": "État porte depuis",
      "git_version": "Version",
      "mouv1": "Mouvement 1",
      "mouv2": "Mouvement 2",
      "mouv3": "Mouvement 3",
      "mouv4": "Mouvement 4",
      "move_state": "Mouvement",
      "move_state_since": "État mouvement depuis",
      "plug_state": "Lampe",
      "plug_watt": "Watt",
      "plug_state_since": "État lampe depuis",
      "state": "État"
    },
    "GepetoList": {
      "table": {
        "box_state": "Etat Box",
        "command": "Commande",
        "data": "Données",
        "gepeto_state": "Etat Gepeto",
        "ip": "IP",
        "latency": "Latence",
        "name": "Nom",
        "zipCode": "Code postal"
      },
      "command": {
        "empty_clean": "Vide et propre",
        "busy": "Occupée",
        "empty_to_clean": "Vide et à nettoyer",
        "cleaning": "Lancer le nettoyage"
      }
    },
    "GepetosPage": 
    {
      "title": "éléments"
    },
    "GepetoPage": 
    {
      "commands": "Actions",
      "data": "Données",
      "network": "Réseau",
      "title": "Gepeto"
    },
    "IntervenerFilter": {
      "box": "Box",
      "job": "Fonction",
      "name": "Nom"
    },
    "IntervenerForm": {
      "aPhone": "un téléphone",
      "anEmail": "un email",
      "company": "Société",
      "description": "Description",
      "email": "Email",
      "emails": "Emails",
      "firstName": "Prénom",
      "job": {
        "cleaning": "Nettoyage",
        "label": "Fonction",
        "maintenance": "Maintenance",
        "municipacility": "Municipalité",
        "territory_responsable": "Responsable territoire",
        "emergency": "Secours"
      },
      "lastName": "Nom",
      "phone": "Téléphone",
      "phones": "Téléphones"
    },
    "IntervenersList": {
      "table": {
        "boxes": "Intervient sur",
        "emails": "Emails",
        "job": {
          "cleaning": "Nettoyage",
          "label": "Fonction",
          "maintenance": "Maintenance",
          "municipacility": "Municipalité",
          "territory_responsable": "Responsable territoire",
          "emergency": "Secours"
        },
        "name": "Nom",
        "phones": "Téléphones"
      }
    },
    "IntervenersPage": {
      "addIntervenerTitle": "Création d'un intervenant",
      "deletePopupTitle": "Êtes-vous sûr de vouloir supprimer l'intervenant"
    },
    "Login": {
      "email": "Email",
      "password": "Mot de passe"
    },
    "Logs": {
      "empty": "Il n'y a pas d'historique pour ce rendez-vous"
    },
    "Menu": {
      "alerts": "Alertes",
      "boxes": "Box",
      "changelog": "Mise à jour",
      "events": "Incidents",
      "interveners": "Intervenants",
      "owners": "Collectivités",
      "slots": "Rendez-vous",
      "users": "Utilisateurs"
    },
    "OwnerForm": {
      "comment": "Commentaire",
      "contact": "Contact",
      "digicode_cleanup": "Digicode nettoyage",
      "name": "Nom"
    },
    "OwnerList": {
      "table": {
        "name": "Nom",
        "nbBoxes": "Box"
      }
    },
    "OwnersPage": {
      "addOwnerTitle": "Ajouter une collectivité",
      "title": "Collectivités"
    },
    "Period": {
      "title": {
        "create": "Création d'une période d'ouverture de box"
      },
      "create": "Une période d'ouverture manquante ?",
      "createButton": "Créer une période d'ouverture"
    },
    "PeriodForm": {
      "intervener": "Intervenant",
      "noCleaningIntervener": "Veuillez assigner à cette box un intervenant, avec un numéro de téléphone, pour le nettoyage",
      "period_type": {
        "cleaning": "Nettoyage",
        "consulting": "Consultations",
        "open": "Ouverture de la box"
      },
      "range": "Plage horaire"
    },
    "Slot": {
      "is_cancel": "Annulé",
      "slotType": {
        "clean": "Nettoyage",
        "maintenance": "Maintenance",
        "patient": "Patient"
      },
      "slotTypeColor": {
        "clean": "blue",
        "maintenance": "red",
        "patient": "green"
      },
      "state": {
        "auto_cancel": "Annulé",
        "cancel": "Annulé",
        "done": "Fait",
        "in_progress": "En cours",
        "late": "En retard",
        "not_confirmed": "Non confirmé",
        "problem": "Anomalie",
        "waiting": "Prévu"
      },
      "stateColor": {
        "auto_cancel": "yellow",
        "cancel": "yellow",
        "done": "green",
        "in_progress": "cyan",
        "late": "orange",
        "not_confirmed": "orange",
        "problem": "red",
        "waiting": "blue"
      }
    },
    "SlotFilter": {
      "box": "Box",
      "name": "Nom",
      "phone": "Téléphone"
    },
    "SlotForm": {
      "appointment_date": "Heure du rdv",
      "box": "Box",
      "comment": "Commentaire",
      "company": "Société",
      "created_at": "Date à laquelle la réservation a été réalisée",
      "digicode": "Digicode",
      "email": "Email",
      "first_name": "Prénom",
      "last_name": "Nom",
      "phone": "Téléphone",
      "range": "Plage horaire",
      "slot_type": {
        "cleaning": "Nettoyage",
        "maintenance": "Maintenance",
        "patient": "Patient"
      },
      "specialistName": "Nom du spécialiste",
      "start": "Heure du RDV"
    },
    "SlotList": {
      "table": {
        "box": "Box",
        "digicode": "Digicode",
        "name": "Nom",
        "phone": "Téléphone",
        "range": "Horaire",
        "slotType": "Type",
        "state": "Status",
        "bookedSlots": "Nombre de réservations dans les 365 derniers jours",
        "previousSlots": "Rendez-vous précédents du patient"
      }
    },
    "SlotsPage": {
      "title": "Rendez-vous"
    },
    "UpdatePassword": {
      "password": "Nouveau mot de passe",
      "passwordConfirmation": "Confirmer le mot de passe"
    },
    "User": {
      "role": {
        "admin": "Administrateur",
        "collectivity": "Collectivité",
        "setup": "Paramétrage",
        "supervisor": "Superviseur",
        "user": "Utilisateur"
      }
    },
    "UserForm": {
      "email": "Email",
      "firstName": "Prénom",
      "lastName": "Nom",
      "owner": "Collectivité",
      "role": "Rôle"
    },
    "UserList": {
      "table": {
        "email": "Email",
        "firstName": "Prénom",
        "lastName": "Nom",
        "roles": "Rôles"
      }
    },
    "UsersPage": {
      "addUserTitle": "Ajouter un utilisateur",
      "title": "Utilisateurs"
    },
    "btn": {
      "back": "Retour",
      "cancel": "Annuler le RDV",
      "submit": "Valider"
    },
    "message": {
      "no": "Non",
      "updateError": "Une erreur est survenue lors de la mise à jour",
      "updateSuccess": "Mise à jour effectuée avec succès",
      "yes": "Oui"
    },
    "notification": {
      "event" : {
        "link": "Voir Incident",
        "type": {
          "closed_event": "Fermeture Incident",
          "new_event": "Nouvel Incident",
          "update_event": "Mise à jour Incident"
        }
      },
      "slot": {
        "cancel": "Annulation d'un RDV",
        "confirmation": "Nouveau RDV",
        "created": "Création d'un RDV",
        "late": "En retard",
        "link": "Voir le RDV"
      }
    }
  },
  "Public": {
    "BoxList": {
      "allSlots": "Voir tous les horaires",
      "change": "Changer",
      "nearestBox": "Box les plus proches de",
      "noBox": "Aucune box disponible autour de cette localisation",
      "now": "RDV immédiat",
      "selectBox": "Sélectionnez une box",
      "today": "aujourd'hui"
    },
    "BoxPage": {
      "availableNow": {
        "button": "Obtenir un digicode",
        "description": "Cliquez pour obtenir un digicode et rentrer dans la box dès maintenant",
        "title": "Box disponible immédiatement"
      },
      "change": "Changer",
      "description": "Vous avez demandé un rendez-vous ce jour à {{date}}, vous avez 10 minutes pour finaliser votre inscription et obtenir votre digicode",
      "faq": "FAQ",
      "more": "Afficher les créneaux suivants",
      "now": "Maintenant",
      "title": "Sélectionnez un rendez-vous",
      "unavailable": "RDV indisponible"
    },
    "CancelSlotPage": {
      "title": "Rendez-vous annulé !"
    },
    "ConfirmSlotForm": {
      "error": "Code invalide ou expiré",
      "resend": "Renvoyer le code",
      "resendSuccess": "Code renvoyé avec succès",
      "submitConfirmation": "Confirmer le numéro",
      "title": "Vous disposez de 15 minutes pour saisir le code SMS reçu pour confirmer votre numéro de téléphone"
    },
    "ConfirmSlotPage": {
      "title": "Confirmez votre rendez-vous"
    },
    "ConfirmedSlot": {
      "cancel": "Annuler le rendez-vous",
      "canceled": "Votre rendez-vous a été annulé",
      "description": "Le digicode permettant d'ouvrir la porte est",
      "modalConfirm": "Êtes vous sûr de vouloir annuler votre rendez-vous ?"
    },
    "ConfirmedSlotPage": {
      "title": "Félicitations, rendez-vous confirmé !"
    },
    "CreateSlotPage": {
      "title": "Confirmez votre rendez-vous"
    },
    "ErrorPage": {
      "403": {
        "description": "Vous n'avez pas les droits pour accéder à cette page",
        "title": "Accès refusé"
      },
      "404": {
        "description": "La page que vous cherchez n'existe pas",
        "title": "Page non trouvée"
      },
      "422": {
        "description": "Une erreur est survenue, veuillez vérifier les informations saisies",
        "title": "Erreur de validation"
      },
      "500": {
        "description": "Une erreur est survenue, veuillez réessayer plus tard",
        "title": "Erreur serveur"
      }
    },
    "Search": {
      "placeholder": "Ville / Code postal",
      "required": "Ce champ est requis",
      "submit": "Rechercher",
      "title": "Trouvez <b>la Box la plus proche</b>"
    },
    "Slot": {
      "age_restriction": "Je suis un patient seul,  ou j'accompagne un adulte, ou j'accompagne un enfant et je m'engage à avoir plus de 16 ans",
      "cgu_1": "J'accepte les ",
      "cgu_2": "conditions générales d'utilisation",
      "civility": "Civilité",
      "country": "Pays",
      "email": "Email",
      "firstName": "Prénom",
      "lastName": "Nom",
      "phone": "Téléphone",
      "submit": "Confirmer"
    },
    "Specialist": {
      "automatic": "Choix automatique",
      "dateSelection": "Sélectionnez une date",
      "empty": "Aucun spécialiste disponible",
      "loading": "Chargement des spécialistes",
      "noSlots": "Aucun créneau disponible",
      "selectReason": "Sélectionnez d'abord un type de RDV",
      "selectSpecialist": "Spécialiste",
      "slotSelection": "Sélection du créneau",
      "slotsAvailable": "Créneaux disponibles"
    }
  },
  "actions": {
    "add": "Ajouter",
    "cancel": "Annuler",
    "comment": "Commenter",
    "confirm": "Confirmer",
    "delete": "Supprimer",
    "login": "Se connecter",
    "save": "Sauvegarder",
    "search": "Rechercher",
    "validate": "Valider"
  },
  "message": {
    "error": "Une erreur est survenue"
  },
  "rules": {
    "required": "Ce champ est requis"
  }
}
